import type { Sku, StockItem } from '@commercelayer/sdk'

export const isInStock = (sku: Sku) =>
  sku.stock_items?.some(isStockItemInStock) ?? false

export const isLastInStockFn = (sku: Sku) => {
  if (!sku.stock_items?.length) return false

  const exactlyOneLastInStock =
    sku.stock_items.filter(isStockItemLastInStock).length === 1

  const allTheOthersOutOfStock = sku.stock_items
    .filter(stockItem => !isStockItemLastInStock(stockItem))
    .every(stockItem => !isStockItemInStock(stockItem))

  return exactlyOneLastInStock && allTheOthersOutOfStock
}

const isStockItemInStock = (stockItem: StockItem) =>
  stockItem.quantity > (stockItem.reserved_stock?.quantity ?? 0)

const isStockItemLastInStock = (stockItem: StockItem) =>
  stockItem.quantity - (stockItem.reserved_stock?.quantity ?? 0) === 1

export const getSkuPriceAndStockInfo = (sku: Sku | undefined) => {
  if (!sku)
    return {
      price: null,
      availableQty: 0,
    }
  const { prices, stock_items } = sku
  const price = prices?.[0]
  const availableQty =
    stock_items?.reduce(
      (availableQty, stockItem) =>
        availableQty +
        stockItem.quantity -
        (stockItem.reserved_stock?.quantity ?? 0),
      0
    ) ?? 0

  return {
    price,
    availableQty,
  }
}
